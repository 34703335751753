import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();
monday.setApiVersion("2024-01");

const API_KEY = "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjI0NDM2NDI0NiwiYWFpIjoxMSwidWlkIjozODg1NjMxOCwiaWFkIjoiMjAyMy0wMy0xNVQxNDoyNToyOC4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTQ5ODY0MzksInJnbiI6ImV1YzEifQ.0lonRouU4poMDHvu80cYsqBhAl0PAYSSRq0eGJhgscg";

monday.setToken(
  API_KEY
);

const filteredWorkspace = [82904];

const mondayAPI = {
  getUsers: async () => {
    let res = await monday.api("query { users { name, photo_small  } }");

    if (!res.data) return null;

    return res.data.users;
  },

  getBoards: async () => {
    let res = await monday.api(
      `query { boards (limit:500) { name id workspace { name id } } }`
    );

    if (!res.data) return null;

    let filteredBoard = res.data.boards.filter((b) => {
      return (
        b.workspace &&
        !filteredWorkspace.includes(b.workspace.id) &&
        !b.name.includes("Sous-éléments") &&
        !b.name.includes("Subitems")
      );
    });

    return filteredBoard;
  },

  getItemsByUser: async (boards, user) => {
    let items = [];
    await Promise.all(
      boards.map(async (b) => {
        // let query = `query {
        //   items_by_column_values (board_id: ${b.id}, column_id: "people", column_value: "${user}") {
        //     id
        //     name
        //     column_values {
        //       id
        //       title
        //       text
        //     }
        //   }
        // }`;
        let post_202310_query = `query {
          items_page_by_column_values (
            board_id: ${b.id}
            columns: [
              {
                column_id: "people",
                column_values: ["${user}"]
              }
            ]
          ) {
              cursor
              items {
                id
                name
                column_values {
                    id
                    text
                }
              }
            }
        }`;
        let res = {}
        try {
          res = await monday.api(post_202310_query);
        }
        catch( e ) {
          res.data = false;
        } 
          let newItems = [];
          if (res?.data?.items_page_by_column_values?.items?.length > 0)
            newItems = res.data.items_page_by_column_values.items.map((i) => {
              return {
                ...i,
                workspaceId: b.workspace.id,
                workspaceName: b.workspace.name,
                boardId: b.id,
                boardName: b.name,
              };
            });

          items = items.concat(newItems);
      })
    );
    if (items.length > 0) items.sort((a, b) => a.workspaceName.localeCompare(b.workspaceName));
    return items;
  },

  updateValue: async (boardId, itemId, columnId, val) => {
    let _val = val.toString();

    let res = await monday.api(`
      mutation {
        change_simple_column_value (board_id: ${boardId.toString()}, item_id: ${itemId.toString()}, column_id: "${columnId}", value: "${_val}") {
            id
        }
      }
    `);
    return res;
  },

  updateCheckbox: async (boardId, itemId, columnId, val) => {
    let res;
    if (val) {
      res = await monday.api(`
      mutation {
          change_multiple_column_values ( item_id: ${itemId}, board_id:${boardId}, column_values: "{\\"checkbox\\" : {\\"checked\\" : \\"true\\"}}") {
              id
          }
        }
      `);
    } else {
      res = await monday.api(`
        mutation {
            change_multiple_column_values ( item_id: ${itemId}, board_id:${boardId}, column_values: "{\\"checkbox\\" : null}") {
                id
            }
          }
        `);
    }
    console.log(res);
    return res;
  },
};

export default mondayAPI;
