import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import "monday-ui-react-core/dist/main.css";
import { Loader } from "monday-ui-react-core";
import mondayAPI from "./mondayAPI";
import Items from "./components/Items";
import Filter from "./components/Filter";
import moment from "moment";
import { getValueFromKey, isInDateRange } from "./utils";
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

const App = () => {
  const [theme, setTheme] = useState("light");
  const [isInMonday, setIsInMonday] = useState(false);
  const [users, setUsers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [optionsUser, setOptionsUser] = useState([]);
  const optionsStatus = [
    { value: "all", label: "Tous" },
    { value: "to-do", label: "Non fait" },
    { value: "done", label: "Fait" },
  ];
  const optionsStatusBilling = [
    { value: "all", label: "Tous" },
    { value: "to-do", label: "Pas facturé" },
    { value: "done", label: "Facturé" },
  ];
  const [userSelected, setUserSelected] = useState("");
  const [statusSelected, setStatusSelected] = useState("");
  const [statusBillingSelected, setStatusBillingSelected] = useState("");
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [date, setDate] = useState({
    startDate: moment().locale('fr').format('LLLL'),
    endDate: moment().locale('fr').format('LLLL'),
  });

  useEffect(() => {
    
    const fetchData = async () => {
      let _users = await mondayAPI.getUsers();
      setIsLoadingUser(false);
      if (_users) {
        setUsers(_users);
      }
  
      let _boards = await mondayAPI.getBoards();
      if (_boards) setBoards(_boards);
    };

    const now = new Date();
    const dayOfWeek = now.getDay();
    const startOfWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - dayOfWeek + 1
    );
    const endOfWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + (7 - dayOfWeek)
    );
    setDate({ startDate: moment(startOfWeek), endDate: moment(endOfWeek) });

    monday.get("context").then((res) => {
      if (!res.data) {
        return 0;
      }
      if (res.data.theme === "dark") setTheme("dark");
      setIsInMonday(true);
      fetchData();
    });
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      let _options = users.map((u) => {
        return {
          value: u.name,
          label: u.name,
          leftAvatar: u.photo_small,
        };
      });
      setOptionsUser(_options);
    }
  }, [users]);

  const handleChangeUser = (e) => {
    if (e?.value) {
      setUserSelected(e.value);
    }
  };

  const handleChangeStatus = (e) => {
    if (e?.value) {
      setStatusSelected(e.value);
    }
  };

  const handleChangeStatusBilling = (e) => {
    if (e?.value) {
      setStatusBillingSelected(e.value);
    }
  };
  
  useEffect(() => {
    const fetchItems = async () => {
      setIsLoadingItems(true);
      let _items = await mondayAPI.getItemsByUser(boards, userSelected);
      if (_items) {
        setItems(_items);
        setFilteredItems(_items);
      }
    };
    if (boards && boards.length > 0) fetchItems();
  }, [userSelected, boards]);

  useEffect(() => {
    if (date.endDate) {
      let _filteredItems = items
        .filter((i) => {
          let _date = getValueFromKey("timeline", i); // Période
          if (!_date) return false;
          return isInDateRange(date, _date);
        })
        .filter((i) => {
          let _status = getValueFromKey("status", i); // Statut
          if (!statusSelected) return true;
          if (statusSelected === "all") return true;
          if (statusSelected === "to-do" && _status !== "Fait") return true;
          if (statusSelected === "done" && _status === "Fait") return true;
          return false;
        })
        .filter((i) => {
          let _facture = getValueFromKey("checkbox", i); // Facturé
          if (!statusBillingSelected) return true;
          if (statusBillingSelected === "all") return true;
          if (statusBillingSelected === "to-do" && _facture !== "v")
            return true;
          if (statusBillingSelected === "done" && _facture === "v") return true;
          return false;
        });
      setFilteredItems(_filteredItems);
      setIsLoadingItems(false);
    }
  }, [date, items, statusSelected, statusBillingSelected]);

  const updateItem = (itemId, columnId, val) => {
    let _items = items.map((i) => {
      if (itemId !== i.id) return { ...i };
      let _column_values = i.column_values.map((c) => {
        if (columnId !== c.id) return { ...c };
        return { ...c, text: val };
      });
      return { ...i, column_values: _column_values };
    });
    setItems(_items);
  };

  return (
    <div className={`App ${theme === "dark" ? "dark" : ""}`}>
      { isInMonday ? (
        <>
          {isLoadingUser ? (
            <div className="loader">
              <Loader />
            </div>
          ) : (
            <>
              <Filter
                optionsUser={optionsUser}
                handleChangeUser={handleChangeUser}
                setDate={setDate}
                date={date}
                optionsStatus={optionsStatus}
                handleChangeStatus={handleChangeStatus}
                optionsBilling={optionsStatusBilling}
                handleChangeStatusBilling={handleChangeStatusBilling}
              />
              <Items
                items={filteredItems}
                isLoading={isLoadingItems}
                updateItem={updateItem}
              />
            </>
          )}
        </>
      ):(
        <div>
          Ouvrir la vue dans{" "}
          <a href="https://sporting-comm-group.monday.com/overviews/4079995">
            monday
          </a>
        </div>
      )}
    </div>
  );
};

export default App;
