import React from "react";
import { Flex, TextField, Dropdown } from "monday-ui-react-core";
import { getValueFromKey, formatDate } from "../utils";
import { useEffect, useState } from "react";
import mondayAPI from "../mondayAPI";

const SingleItem = ({ item, updateItem }) => {
  const optionsStatus = [
    { value: "", label: "" },
    { value: "A faire", label: "A faire" },
    { value: "Bloqué", label: "Bloqué" },
    { value: "En cours", label: "En cours" },
    { value: "Fait", label: "Fait" },
  ];

  const [isEditingTime, setIsEditingTime] = useState(false);
  const [isEditingEstim, setIsEditingEstim] = useState(false);
  const [isEditingStatus, setIsEditingStatus] = useState(false);

  const [newTime, setNewTime] = useState(0);
  const [newEstim, setNewEstim] = useState(0);
  const [newStatus, setNewStatus] = useState(getValueFromKey("status", item));

  const [newStatusBilling, setNewStatusBilling] = useState(
    getValueFromKey("checkbox", item)
  );

  useEffect(() => {
    let _newTime = getValueFromKey("chiffres", item);
    setNewTime(parseInt(_newTime));

    let _newEstim = getValueFromKey("chiffres2", item);
    setNewEstim(parseInt(_newEstim));

    setNewStatusBilling(getValueFromKey("checkbox", item));

    setNewStatus(getValueFromKey("status", item));
  }, [item]);

  const handleClickWorkspace = () => {
    window.open(
      `https://sporting-comm-group.monday.com/workspaces/${item.workspaceId}`
    );
  };

  const handleClickBoard = () => {
    window.open(
      `https://sporting-comm-group.monday.com/boards/${item.boardId}`
    );
  };

  const handleBlurTime = (e) => {
    mondayAPI.updateValue(
      item.boardId,
      item.id,
      'chiffres', // getIdFromKey("Temps passé", item),
      e.target.value
    ).then( (res) => {
      if (res?.data) {
        updateItem(item.id, "chiffres", e.target.value);
        setNewEstim(e.target.value);
      }
    });
    setIsEditingTime(false);
  };

  const handleBlurEstim = (e) => {
    mondayAPI.updateValue(
      item.boardId,
      item.id,
      "chiffres2", //getIdFromKey("Estimation", item),
      e.target.value
    ).then( (res) => {
      if (res?.data) {
        updateItem(item.id, "chiffres2", e.target.value);
        setNewEstim(e.target.value);
      }
    });
    setIsEditingEstim(false);
  };

  const handleChangeStatusBilling = () => {
    if (newStatusBilling === "") {
      updateItem(item.id, "checkbox", "v");
      mondayAPI.updateCheckbox(
        item.boardId,
        item.id,
        "checkbox", //getIdFromKey("Facturé", item),
        true
      );
      setNewStatusBilling("v");
    }

    if (newStatusBilling === "v") {
      updateItem(item.id, "checkbox", "");
      mondayAPI.updateCheckbox(
        item.boardId,
        item.id,
        "checkbox", //getIdFromKey("Facturé", item),
        false
      );
      setNewStatusBilling("");
    }
  };

  const handleChangeStatus = (e) => {
    mondayAPI.updateValue(
      item.boardId,
      item.id,
      "status", //getIdFromKey("Statut", item),
      e.value
    ).then( (res) => {
      if (res?.data) {
        updateItem(item.id, "status", e.value);
        setNewStatus(e.value);
      }
    });
  };

  useEffect(() => {
    setIsEditingStatus(false);
  }, [newStatus]);

  return (
    <Flex
      justify="SpaceBetween"
      className={`single-item ${newStatusBilling === "v" ? "factu" : ""}`}
    >
      <div onClick={handleClickWorkspace} className="link">
        {item.workspaceName}
      </div>
      <div onClick={handleClickBoard} className="link">
        {item.boardName}
      </div>
      <div>{item.name}</div>
      <div onClick={() => setIsEditingTime(true)}>
        {isEditingTime ? (
          <TextField
            value={isNaN(newTime) ? 0 : newTime}
            size={TextField.sizes.SMALL}
            type={TextField.types.NUMBER}
            onBlur={handleBlurTime}
            onChange={(val) => setNewTime(val)}
          />
        ) : isNaN(newTime) ? (
          ""
        ) : (
          newTime
        )}
      </div>
      <div onClick={() => setIsEditingEstim(true)}>
        {isEditingEstim ? (
          <TextField
            value={isNaN(newEstim) ? 0 : newEstim}
            size={TextField.sizes.SMALL}
            type={TextField.types.NUMBER}
            onBlur={handleBlurEstim}
            onChange={(val) => setNewEstim(val)}
          />
        ) : isNaN(newEstim) ? (
          ""
        ) : (
          newEstim
        )}
      </div>
      <div>
        {getValueFromKey("timeline", item)
          ? formatDate(getValueFromKey("timeline", item))
          : ""}
      </div>
      <div
        className={`status ${
          getValueFromKey("status", item)
            ? getValueFromKey("status", item)
                .toLowerCase()
                .replaceAll(" ", "_")
                .replaceAll("é", "e")
            : ""
        }`}
        onClick={() => setIsEditingStatus(true)}
      >
        {isEditingStatus ? (
          <Dropdown
            options={optionsStatus}
            value={newStatus}
            className="dropdown-stories-styles_with-chips dropdown-status"
            onChange={handleChangeStatus}
            onBlur={() => setIsEditingStatus(false)}
            autoFocus
            size={Dropdown.size.SMALL}
          />
        ) : (
          newStatus
        )}
      </div>
      <div className="link billing" onClick={handleChangeStatusBilling}>
        {newStatusBilling}
      </div>
    </Flex>
  );
};

export default SingleItem;
