import React from "react";
import { Flex } from "monday-ui-react-core";
const HeaderItems = () => {
  return (
    <Flex justify="SpaceBetween" className="header-item">
      <div>Espace de travail</div>
      <div>Projet</div>
      <div>Element</div>
      <div>Temps</div>
      <div>Estimation</div>
      <div>Période</div>
      <div>Statut</div>
      <div>Facturé</div>
    </Flex>
  );
};

export default HeaderItems;
