import React, { useEffect, useState } from "react";
import HeaderItems from "./HeaderItems";
import SingleItem from "./SingleItem";
import { Loader } from "monday-ui-react-core";
import FooterItems from "./FooterItems";

const Items = ({ items, isLoading, updateItem }) => {
  const [_items, _setItems] = useState(items);
  useEffect(() => {
    _setItems(items);
  }, [items]);
  return (
    <div>
      <HeaderItems />
      {!isLoading ? (
        <>
          {_items.length < 1 ? (
            <div className="no-item">Pas d'items </div>
          ) : (
            <>
              {_items.map((item, i) => {
                return (
                  <SingleItem item={item} key={i} updateItem={updateItem} />
                );
              })}
              <FooterItems items={_items} />
            </>
          )}
        </>
      ) : (
        <div className="loader">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Items;
