import React, { useState } from "react";
import { Flex } from "monday-ui-react-core";
import { Dropdown, DatePicker } from "monday-ui-react-core";
import { getDisplayedDate } from "../utils";

const Filter = ({
  optionsUser,
  handleChangeUser,
  setDate,
  date,
  optionsStatus,
  handleChangeStatus,
  optionsBilling,
  handleChangeStatusBilling,
}) => {
  const [isEditingDate, setIsEditingDate] = useState(false);

  const handleChangeDate = (date) => {
    setDate(date);
    if (date.endDate) {
      setIsEditingDate(false);
    }
  };

  return (
    <Flex className="filter">
      <Dropdown
        options={optionsUser}
        className="dropdown-stories-styles_with-chips"
        onChange={handleChangeUser}
        placeholder={"Séléctionner un utilsateur"}
        clearable={false}
      />
      <div onClick={() => setIsEditingDate(!isEditingDate)} className="date">
        {getDisplayedDate(date)}
        {isEditingDate && (
          <DatePicker
            date={date.startDate}
            endDate={date.endDate}
            className="input-date"
            range
            data-testid="date-picker"
            onPickDate={(d) => handleChangeDate(d)}
          />
        )}
      </div>

      <Dropdown
        options={optionsStatus}
        className="dropdown-stories-styles_with-chips"
        onChange={handleChangeStatus}
        placeholder={"Séléctionner un statut"}
        clearable={false}
      />
      <Dropdown
        options={optionsBilling}
        className="dropdown-stories-styles_with-chips"
        onChange={handleChangeStatusBilling}
        placeholder={"Séléctionner un statut facturation"}
        clearable={false}
      />
    </Flex>
  );
};

export default Filter;
