import moment from "moment";

const getValueFromKey = (key, item) => {
  let val = item.column_values.filter((v) => {
    return key === v.id;
  });

  if (val.length > 0) return val[0].text;
  else return null;
};

const getIdFromKey = (key, item) => {
  let val = item.column_values.filter((v) => {
    return key === v.title;
  });

  if (val.length > 0) return val[0].id;
  else return null;
};

const getDisplayedDate = (date) => {
  if (date.startDate === date.endDate) {
    return moment(date.startDate).format("MMMM D");
  }

  let startMonth = moment(date.startDate).format("MMMM");
  let endMonth = moment(date.endDate).format("MMMM");

  let startDay = moment(date.startDate).format("D");
  let endDay = moment(date.endDate).format("D");

  if (startMonth === endMonth) {
    return `${startMonth} ${startDay}-${endDay}`;
  }

  if (date.startDate && date.endDate && date.startDate !== date.endDate) {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
  }

  return [];
};

const formatDate = (date) => {
  let dateArr = date.split(" - ");

  let startDate = dateArr[0];
  let endDate = dateArr[1];

  if (startDate === endDate) {
    return moment(date.startDate).format("MMMM D");
  }

  let startMonth = moment(startDate).format("MMMM");
  let endMonth = moment(endDate).format("MMMM");

  let startDay = moment(startDate).format("D");
  let endDay = moment(endDate).format("D");

  if (startMonth === endMonth) {
    return `${startMonth} ${startDay}-${endDay}`;
  }

  if (startDate && endDate && startDate !== endDate) {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
  }

  return [];
};

const isInDateRange = (dateRange, date) => {
  if (!dateRange) return true;

  let startDateRange = moment(dateRange.startDate).startOf("day").unix();
  let endDateRange = moment(dateRange.endDate).startOf("day").unix();

  let dateArr = date.split(" - ");
  let startDate = moment(dateArr[0]).unix();
  let endDate = moment(dateArr[1]).unix();

  if (
    (startDateRange <= startDate && startDate <= endDateRange) ||
    (startDateRange <= endDate && endDate <= endDateRange)
  ) {
    return true;
  }
  return false;
};

export {
  getValueFromKey,
  getDisplayedDate,
  isInDateRange,
  getIdFromKey,
  formatDate,
};
