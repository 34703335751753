import React, { useEffect, useState } from "react";
import { Flex } from "monday-ui-react-core";

import { getValueFromKey } from "../utils";
const FooterItems = ({ items }) => {
  const [totalTime, setTotalTime] = useState(0);
  const [totalEstim, setTotalEstim] = useState(0);

  useEffect(() => {
    let _totalTime = 0;
    let _totalEstim = 0;

    if (items && items.length > 0) {
      items.forEach((i) => {
        let time = parseInt(getValueFromKey("chiffres", i));
        let estim = parseInt(getValueFromKey("chiffres2", i));
        if (!time) time = 0;
        if (!estim) estim = 0;
        _totalTime += time;
        _totalEstim += estim;
      });

      setTotalTime(_totalTime);
      setTotalEstim(_totalEstim);
    }
  }, [items]);

  return (
    <Flex justify="SpaceBetween" className="footer-item">
      <div></div>
      <div></div>
      <div></div>
      <div>
        <span>{totalTime}</span>
        <span>Somme</span>
      </div>
      <div>
        <span>{totalEstim}</span>
        <span>Somme</span>
      </div>
      <div></div>
      <div></div>
      <div></div>
    </Flex>
  );
};

export default FooterItems;
